<template>

    <b-row>
        
        <b-col cols="12">
            
        </b-col>
        
        <b-col cols="12">
            <b-card>
                <showAt breakpoint="large">
                    <b-row>
                        <b-col md="7">
                            <form-view @dataChanged="dataChanged" />
                            <hr class="mt-2 mb-2">
                            <forecast-view @dataChanged="forecastDataChanged" :period="period" />
                        </b-col>
                        <b-col md="4" offset-md="1">
                            <results-view :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="total_btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" />
                        </b-col>
                    </b-row>
                </showAt>
                
                <showAt breakpoint="medium">
                    
                    <div>
                        <form-view @dataChanged="dataChanged" />
                        <hr class="mt-2 mb-2">
                        <forecast-view @dataChanged="forecastDataChanged" :period="period" />
                        <hr class="mt-2 mb-2">
                        <results-tablet-view :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="total_btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" />
                    </div>
                    
                </showAt>
                
                
                <showAt breakpoint="small">
                    <div>
                        <form-view @dataChanged="dataChanged" />
                        <hr class="mt-2 mb-2">
                        <forecast-view @dataChanged="forecastDataChanged" :period="period" />
                        <results-mobile-view :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="total_btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" />
                    </div>
                </showAt>
                
            </b-card>
        </b-col>
        
        <b-col cols="12">
            <b-tabs pills v-model="selectedTabIndex" style="min-height: 355px;">
                <b-tab :title="$t('charts.complexity-network')">
                    <DifficultGraphView v-if="selectedTabIndex === 0" :percent="diff_percent" :period="period" />
                </b-tab>
                <b-tab :title="$t('charts.btc-rate')">
                    <BitcoinGraphView v-if="selectedTabIndex === 1" :percent="market_price_percent" :period="period" />
                </b-tab>
                <b-tab :title="$t('charts.profitability')">
                    <ProfitGraphView v-if="selectedTabIndex === 2"  :total_price="total_btc_price" :period="period" :btc_percent_grow="market_price_percent" :difficult_percent_grow="diff_percent" :total_ths="total_ths" />
                </b-tab>
            </b-tabs>
        </b-col>


        <h1 class="visually-hidden">Хешрейт и аренда хешрейта. Калькулятор хешрейта</h1>
        <p class="visually-hidden">С каждым годом о рынке криптовалют говорят всё больше. Уже сейчас новости о том, что биткоин обновил очередной максимум, не сходят со страниц отечественных и мировых средств массовой информации. Майнинг, то есть процесс добычи цифровых денег, всё чаще преподносится как способ получить значительную доходность. Конечно, опрометчиво относиться к майнингу как к источнику прибыли, который не требует знаний и инвестиций. Предлагаем разобрать базовые понятия криптовалютчиков, в частности, хешрейт, калькулятор майнинга, а также аренда хешрейта, понимание которых необходимо для оценки вероятной прибыли.</p>
        <h2 class="visually-hidden">Что такое хешрейт?</h2>
        <p class="visually-hidden">Хешрейт – это единица мощности оборудования, которое используется для майнинга. Слово «хешрейт» («hashrate») происходит от сочетания английских терминов «hash», то есть состоящий из цифр и букв код определённой длины, необходимый для передачи данных, и «rate» – скорость.</p>
        <p class="visually-hidden">Хешрейт показывает, как быстро используемые майнером ресурсы позволят решить криптографическую задачу, необходимую для генерации цифровых валют. Чем выше хешрейт, тем значительнее может быть прибыльность майнинга.</p>
        <p class="visually-hidden">Хеш можно сравнить с ключом, который используется для создания очередного блока в цепи блокчейн. Соответственно, единицей измерения хешрейта является «хеш в секунду», обозначается латинскими буквами H/s. Современное оборудование способно ежесекундно производить огромное количество операций, поэтому на практике данные о хешрейте обычно измеряются в гигахешах (1 млрд H/s), терахешах (1 трлн H/s) и перахешах (1 квадриллион H/s).</p>
        <h3 class="visually-hidden">Что такое калькулятор майнинга?</h3>
        <p class="visually-hidden">Калькулятор майнинга (калькулятор хешрейта) – это вычислительное устройство, которое предназначено для подсчёта потенциальной доходности добычи какой-либо монеты. Принимая в расчёт сегодняшний курс криптовалюты, данный инструмент выдаёт информацию о том, сколько электронных денег то или иное оборудование сможет сгенерировать за сутки, месяц или год. Кроме того, прогнозируемая прибыль переводится в фиатные валюты, например, доллары.</p>
        <p class="visually-hidden">Калькулятор майнинга представляет собой сервис, способный в режиме онлайн по определённому алгоритму на основе введённых пользователем параметров оценить прибыль, которую может принести майнинг, а также вычислить срок окупаемости оборудования для добычи криптовалюты. Естественно, речь идёт об ориентировочных результатах, которые обычно актуальны на момент обращения к сайту для вычислений. На практике курс любой цифровой валюты, например, Bitcoin или Ethereum, может существенно взлететь или, напротив, упасть, в результате обстоятельств, которые не получится спрогнозировать заранее.</p>
        <h3 class="visually-hidden">Какими бывают калькуляторы майнинга?</h3>
        <p class="visually-hidden">Сегодня в интернете доступно множество калькуляторов майнинга. Предлагаемые на российских и иностранных сайтах инструменты можно условно разделить на два типа. Во-первых, это сервисы для анализа потенциальной прибыльности криптовалютных ферм. Во-вторых, калькуляторы хешрейта облачного майнинга.</p>
        
        <p class="visually-hidden">Чтобы вычислить потенциальную доходность криптофермы, потребуются следующие параметры:</p>
        <ul class="visually-hidden">
        <li class="visually-hidden">модель и количество используемых в работе видеокарт. Вместо этих показателей можно сразу выбрать точный хешрейт оборудования;</li>
        <li class="visually-hidden">объём электричества, необходимого для обеспечения работы фермы, а также его стоимость;</li>
        <li class="visually-hidden">комиссия майнинг-пула (это опциональный показатель, который желательно принимать в расчёт, ведь он способен оказать влияние на итоговую прибыль майнинга).</li>
        </ul>
        <p class="visually-hidden">Анализ потенциальной доходности облачного майнинга, то есть добычи криптовалют за счёт аренды хешрейта, строится несколько иначе. Обычно на платформе для майнинга представлен собственный калькулятор, который проводит подсчёт на основе выбранных пользователем суммы капиталовложений и срока действия контракта. Затем на основе прогнозируемых значений уровня сложности майнинга и курса криптовалюты, с которой предполагается вести работу, сервис определяет ожидаемый доход и мощность контракта.</p>
        <p class="visually-hidden">Подведём итоги. Хешрейт представляет собой ключевой индикатор работы майнеров. Калькулятор майнинга по хешрейту – это инструмент, позволяющий по мощности, которой располагает криптодобытчик, вычислить ориентировочную прибыль майнинга и время окупаемости первоначальных вложений.</p>

        
    </b-row>
    
</template>

<script>
    
    import FormView from "./form"
    
    import ResultsView from "./widgets/results"
    import ResultsTabletView from "./widgets/results.tablet"
    import ResultsMobileView from "./widgets/results.mobile"
    
    import ForecastView from "./forecast"

    import DifficultGraphView from "./graphs/difficult"
    import BitcoinGraphView from "./graphs/bitcoin"
    import ProfitGraphView from "./graphs/profit"

    export default {
        metaInfo() {
            const title = this.$t('title-pages.hashrate-rental');
            return {
            title: title,
            titleTemplate: null,
            meta: [
                {
                vmid: 'description',
                name: 'description',
                content: 'Платформа Qubity предоставляет в аренду мощность майнингового оборудования с включенным сервисным обслуживанием в контракт. Аренда хешрейта и майнинг возможны с любой суммы. На сайте представлен калькулятор майнинга по хешрейту с прогнозируемыми значениями и ожидаемым доходом'
                }
            ]
            }
        },
        data() {
            return {
                
                selectedTabIndex: 0,
                
                total_btc_price: 0,
                period_profit: 0,
                total_ths: 0,
                current_price: 0,
                period: 12,
                diff_percent: 0,
                market_price_percent: 10
            }
        },
        methods: {
            dataChanged( args ) {
                this.total_btc_price = args.total_btc_price;
                this.period_profit = args.period_profit;
                this.total_ths = args.total_ths;
                this.current_price = args.current_price;
                this.period = args.period;
            },
            
            forecastDataChanged( args ) {
                this.diff_percent = args.difficult_percent;
                this.market_price_percent = typeof args.market_price_percent == 'number' ? args.market_price_percent : 0;
            },
        },
        components: {
            FormView,
            
            ResultsView,
            ResultsTabletView,
            ResultsMobileView,
            
            ForecastView,
            
            DifficultGraphView,
            BitcoinGraphView,
            ProfitGraphView
            
        },
        watch: {

        },
        mounted() {

        }

    }

</script>