<template>

    <validation-observer ref="calculator" class="slider-hover-prevent">

        <b-form-group class="form-group-with-label">
            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                <div style="flex-grow: 1;">

                    <validation-provider
                        #default="{ errors }"
                        name="Investments"
                        :rules="rules"
                    >
                        <div v-if='errors[0]' class='text-danger'>
                            <span  style="font-size: 12px; font-weight: 500;">
                                {{$t('calculator.invest')}} 
                            </span>
                            <feather-icon icon='AlertCircleIcon' />
                        </div>
                        <span v-else class='text-muted' style="font-size: 12px;">{{$t('calculator.invest')}} </span>

                        <b-form-input
                            type="number"
                            :placeholder="$t('calculator.investment-amount')"
                            v-model.number="total_price"
                            @change="calcTotalThs()"
                        />
                    </validation-provider>

                </div>
                <div class="mt-auto mb-auto">
                    <b-dropdown
                      :text="ticker"
                      variant="muted"
                      size="sm"
                      right
                    >
                      <b-dropdown-item @click="changeCurrencyType('USD')">USD</b-dropdown-item>
                      <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>

                    </b-dropdown>
                </div>
            </div>

            <vue-slider
                v-model="total_price"
                :min="sliderOptions.min"
                :max="sliderOptions.max"
                :dotSize="18"
                :height="6"
                :contained="false"
                :interval="sliderOptions.step"
                :duration="0.25"
                tooltip="none"
                @change="calcTotalThs()"
            />

        </b-form-group>

        <div v-if="ticker === 'USD'" class="d-flex justify-content-between mb-1">
            <span class="text-muted" style="font-size: 12px;">100$</span>
            <span class="text-muted" style="font-size: 12px;">10 {{$t('calculator.thousand')}} $</span>
        </div>
        <div v-else class="d-flex justify-content-between mb-1">
            <span class="text-muted" style="font-size: 12px;">{{sliderOptions.min}} ₿</span>
            <span class="text-muted" style="font-size: 12px;">{{sliderOptions.max}} ₿</span>
        </div>

        <b-form-group class="form-group-with-label">

            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                <div style="flex-grow: 1;">
                    <validation-provider
                        #default="{ errors }"
                        name="Investments"
                        rules="required|integer"
                    >
                        <div v-if='errors[0]' class='text-danger'>
                            <span  style="font-size: 12px; font-weight: 500;">
                                {{$t('calculator.contract-validity')}}
                            </span>
                            <feather-icon icon='AlertCircleIcon' />
                        </div>
                        <span v-else class='text-muted' style="font-size: 12px;">{{$t('calculator.contract-validity')}}</span>
                        <b-form-input
                            type="number"
                            :placeholder="$t('calculator.contract-validity')"
                            v-model.number="period"
                            :formatter="periodFormatter"
                            @input="calcTotalThs()"

                        />
                    </validation-provider>
                </div>
                <div class="mt-auto mb-auto">
                    <span class="text-muted">{{$t('calculator.mon')}}</span>
                </div>
            </div>

            <vue-slider
                v-model="period"
                :min="24"
                :max="36"
                :dotSize="18"
                :height="6"
                :contained="false"
                :interval="1"
                :duration="0.25"
                tooltip="none"
                @change="calcTotalThs()"
            />
        </b-form-group>

        <div class="d-flex justify-content-between mb-1">
            <span class="text-muted" style="font-size: 12px;">24 {{$t('decl.months')}}</span>
            <span class="text-muted" style="font-size: 12px;">36 {{$t('decl.months')}}</span>
        </div>

    </validation-observer>

</template>

<script>

    import User from "@/modules/user/"

    import VueSlider from 'vue-slider-component'
    import Blockchain from "@/modules/blockchain"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'

    import {
        required, min, max, digits, integer, minint, maxint
    } from '@/@core/utils/validations/validations'

    export default {

        data() {
           return {
                Blockchain,

                sliderOptions: {
                    step: 100,
                    max: 10000,
                    min: 100
                },

                User,

                ticker: 'USD',

                price: parseFloat(User.contracts.values.P),
                price_with_discount: User.contracts.values.P,

                ths: 100,
                period: 24,
                investments: 320,
                total_price: 1000,
                max_ths: 10000,
                is_loading: false,

                btn_month_percent: 9.5,
                type: 2,
                paymentType: 'card',

                required,
                minint,
                maxint,
                min,
                max,
                digits,
                integer,

                rules: "required|minint:100|maxint:10000"

            }
        },
        methods: {

            summFormatter( args ) {

                let val = parseInt( args );

                if( val < 100 ) {
                    val = 100;
                } else if( val > 10000 ) {
                    val = 10000;
                }

                return val;

            },

            periodFormatter( args ) {

                let val = parseInt( args );

                if( val < 24 ) {
                    val = 24;
                } else if( val > 36 ) {
                    val = 36;
                }

                return val;

            },

            changeCurrencyType( value ) {

                if( this.ticker === value ) return;

                this.ticker = value;
                switch( value ) {

                    case 'USD' :

                        this.total_price = this.total_price * this.$blockchain.tickers.values.USD.last;

                        this.total_price = this.total_price.toFixed(0);

                        this.sliderOptions.step = 100;
                        this.sliderOptions.min = 100;
                        this.sliderOptions.max = 10000;

                        this.rules = "required|minint:"+this.sliderOptions.min+"|maxint:"+this.sliderOptions.max;

                        break;


                    case 'BTC' :

                        this.total_price = this.total_price / this.$blockchain.tickers.values.USD.last;

                        this.sliderOptions.step = 0.00001;
                        this.sliderOptions.min = parseFloat((100 / this.$blockchain.tickers.values.USD.last).toFixed(5));
                        this.sliderOptions.max = parseFloat((10000 / this.$blockchain.tickers.values.USD.last).toFixed(5));

                        this.rules = "required|minint:"+this.sliderOptions.min+"|maxint:"+this.sliderOptions.max;

                        break;

                }

            },


            getPeriodProfit() {
                return (((86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) ) - ( this.ths * 0.000042 * this.period ) ;
            },

            getDynamicPeriodProfit() {
                return ( this.btn_month_percent * this.getMounthProfit() * this.period) + this.getPeriodProfit();
            },

            getMounthProfit() {
                return (((86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[User.currency].last ) - (this.ths * 2.5) ;
            },

            getDailyProfit() {
                return (((86400 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[User.currency].last );
            },

            getTotalBTCPrice() {

                if( this.ticker === 'USD' ) {
                   return this.total_price / this.$blockchain.tickers.values.USD.last;
                } else {
                    return this.total_price;
                }

            },

            calcTotalThs() {

                if( this.ticker === 'USD' ) {
                    if ( this.total_price < 100 ) {
                        this.total_price = 100;
                    } else if( this.total_price > 10000 ) {
                        this.total_price = 10000;
                    }
                } else {
                    if ( this.total_price < this.sliderOptions.min ) {
                        this.total_price = this.sliderOptions.min;
                    } else if( this.total_price > this.sliderOptions.max ) {
                        this.total_price = this.sliderOptions.max;
                    }
                }

                var total_price = 0;

                if( this.ticker === 'USD' ) {
                    total_price = this.total_price;
                } else {
                    total_price = this.total_price * this.$blockchain.tickers.values.USD.last;
                }

                let power =  total_price / this.getCurrentPrice() ;

                if( power > 10000 ) {
                    power = 10000;
                }

                this.ths = power;

                this.$refs.calculator.validate().then( success => {
                    if(!success || this.ths === 0) return;
                    this.$emit("dataChanged", {
                        "total_btc_price" : this.getTotalBTCPrice(),
                        "period" : this.period,
                        "period_profit" : this.getPeriodProfit(),
                        "total_ths": this.ths,
                        "current_price" : this.getCurrentPrice()
                    });
                });

            },

            getSizeDiscount() {

                let discountForSize = 0;

                if( this.ths > 1 ) {
                    discountForSize = 2500 * (this.power / 10000);
                }

                return (discountForSize / 10000) * 100;

            },

            getPeriodDiscount() {

                let discount = 0;

                if( this.form.period > 24 ) {
                    discount = ( this.period - 24 ) * 0.5;
                }

                return (discount / this.price) * 100 ;

            },


            getCurrentPrice() {

                let price = this.price * 12;
                let price_per_month = this.price;

                for(let i = 13 ; i <= this.period; i++ ) {
                    price_per_month = price_per_month * 0.9;
                    price = price + price_per_month;
                }

                this.price_with_discount = price;

                return price;

            },

            getTotalPrice() {
                this.total_price = this.ths * this.getCurrentPrice();
            },

            getPeriodHolder() {
                var placeholder = this.declOfNum( this.period, ['месяц', 'месяца', 'месяцев']);
                return `${placeholder}`;
            },

            declOfNum(number, titles) {
                let cases = [2, 0, 1, 1, 1, 2];
                if(number === 0) {
                    return titles[2];
                }
                return titles[ (number % 100 > 4 && number % 100 < 20 )? 2 : cases[(number%10<5)?number%10:5] ];
            },
        },
        components: {
            VueSlider,
            ValidationProvider,
            ValidationObserver,
        },
        watch: {
            "User.currency" : function() {
                this.$emit("dataChanged", {
                    "total_btc_price" : this.getTotalBTCPrice(),
                    "period" : this.period,
                    "period_profit" : this.getPeriodProfit(),
                    "total_ths": this.ths,
                    "current_price" : this.getCurrentPrice()
                });
            },

            "Blockchain.tickers.values.USD.last" : function() {
                this.$emit("dataChanged", {
                    "total_btc_price" : this.getTotalBTCPrice(),
                    "period" : this.period,
                    "period_profit" : this.getPeriodProfit(),
                    "total_ths": this.ths,
                    "current_price" : this.getCurrentPrice()
                });
            }
        },
        mounted() {
            this.calcTotalThs();
        }

    }

</script>


<style lang="scss" >
    @import '@core/scss/vue/libs/vue-slider.scss';
</style>
