<template>
    <div>
        
        <b-row>
            
            <b-col>
                <p style="margin-bottom: 12px;">{{$t('calculator.expected-income-in')}}<b-link @click="changeCurrency"> {{ User.currency }}</b-link> </p>
                <div class="d-flex">
                    <p style="font-size: 28px; font-weight: 600;">{{ (summary - fee - service).formatMoney(0,' ', ' ') }} <span class="font-weight-normal">{{ User.getSymbol() }}</span> </p>
                    <span  v-if="percent > 0" style="color: #3ED085; padding-left: 5px; ">
                        +{{ percent }}%
                    </span>
                    <span  v-else style="color: #FF6F71; padding-left: 5px; ">
                        {{ percent }}%
                    </span>
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.income')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover text-muted"
                        style="margin: 3px" 
                    />
                </div>
                <span class="block-view">
                    {{ total_ths.formatMoney(2,' ', ' ') }} TH/s <span class="text-muted">{{$t('calculator.contract-value')}}</span>
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.ths')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover text-muted"
                        style="margin: 0px 0px 3px 3px;" 
                    />
                </span>
            </b-col>
            
            <b-col>
                <div style="font-size: 14px; margin-bottom: 24px;">
                    <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light d-flex align-items-center">
                        {{$t('calculator.cost-1ths-month')}}
                        <feather-icon 
                            v-b-popover.hover.top="$t('popovers.ths')"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover" 
                            style="margin-left: 6px;"
                        />
                    </span>
                    <span>
                       {{ price.formatMoney( 2,' ', ' ') }} $
                    </span>
                </div>

                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light d-flex align-items-center">
                        {{$t('calculator.monthly-electricity-fee')}}
                        <feather-icon 
                            v-b-popover.hover.top="$t('popovers.electricity-fee-a')+ ' ' +parseFloat($user.contracts.values.E)+ ' ' +$t('popovers.electricity-fee-b')"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover" 
                            style="margin-left: 6px;"
                        />
                    </span>
                    <span>
                        {{ month_energy_payout_usd.formatMoney( User.currency === "BTC" ? 10 : 2, " ", " ") }} {{ User.getSymbol() }}
                    </span>
                </div> 

                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light d-flex align-items-center">
                        {{$t('calculator.pool')}}
                          <feather-icon 
                                v-b-popover.hover.top="$t('popovers.pool')"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover" 
                                style="margin-left: 6px;"
                            />
                    </span>
                    {{ fee.formatMoney( User.currency === 'BTC' ? 5 : 2 ,' ', ' ') }} {{ User.getSymbol() }}
                </div>

                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light d-flex align-items-center">
                            {{$t('calculator.service-fee')}}
                            <feather-icon 
                                v-b-popover.hover.top="$t('popovers.service-fee')"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover" 
                                style="margin-left: 6px;"
                            />
                    </span>
                    {{ service.formatMoney( User.currency === 'BTC' ? 5 : 2 ,' ', ' ') }} {{ User.getSymbol() }}
                </div>

                </div>    
            </b-col>
            
        </b-row>
       

        <div style="margin-top: 24px;">
        
            <b-button @click="createContract" size="lg" variant="primary" pill block style="border-radius: 10px !important; height: 44px; font-size: 14px;">{{$t('contracts.btns.create')}}</b-button>
        
            <p class="text-muted" style="font-size: 12px; line-height: 14px; font-weight: 300; margin-top: 16px;">
               {{$t('calculator.conditions-of-profitability-no-offer')}}
                <b-link :to="{ name: 'user-documents', params: { type: 'public-offer' } }" >{{$t('calculator.public-offer')}}</b-link>.
            </p>
            
        </div>
        
        <b-card no-body bg-variant="gray" style="margin-top: 32px;">
                    
            <b-card-title style="font-size: 14px; margin: 16px 20px 8px 20px;">
                {{$t('calculator.mining-advantages')}}
                <feather-icon 
                    v-b-popover.hover.top="$t('popovers.comparison')"
                    icon="AlertCircleIcon" 
                    class="cursor-pointer text-muted info-hover" 
                />
            </b-card-title>

            <b-card-body style="padding: 0 20px 20px 20px;">

                <span>{{$t('calculator.buy-contract')}}</span>

                <div class="d-flex">
                    <b-progress-bar
                        :min="0"
                        :value="( period / 24 ) * 100"
                        :max="100"
                        label=" "
                        class="mt-auto mb-auto mr-1"
                        style="height: 8px; border-radius: 8px; background-color: #3ED085; max-width: 75%;"
                     />
                    <div  class="text-muted" style="margin-right: 4px;">{{ summary_in_btc.formatMoney(5,' ', ' ') }} ₿ </div>

                </div>



                <p class="text-primary font-medium-5" style="margin-bottom: 4px;">vs</p>

                <span>{{$t('calculator.buy-btc')}}</span>

                <div class="d-flex ">
                    <b-progress-bar
                        :min="0"
                        :value="( (period / 3 ) / 24 ) * 100"
                        :max="100"
                        label=" "
                        class="mt-auto mb-auto mr-1"
                        style="height: 8px; border-radius: 8px; background-color: #9CA2B1; max-width: 75%;"
                     />
                    <div class="text-muted" style="margin-right: 4px;">{{ total_btc_price.formatMoney(5,' ', ' ') }} ₿ </div>
                </div>

            </b-card-body>

        </b-card>
                
        
    </div>
</template>

<script>
    
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                User
            }
        },
        props: {
            
            total_ths: {
                type: Number,
                default: 0
            },
            current_price: {
                type: Number,
                default: 0
            },
            total_btc_price: {
                type: Number,
                default: 0
            },
            
            calcType: {
                type: Number, 
                default: 0 // history
            },
            
            diff_history: {
                type: Number,
                default: 10,
            },
            
            btc_percent: {
                type: Number,
                default: 10
            },
            
            diff_percent: {
                type: Number,
                default: 0
            },
            
            period: {
                type: Number,
                default: 12
            },
            
            market_price_percent: {
                type: Number,
                default: 10
            }
            
            
        },
        methods: {
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            
            createContract( args ) {
                
                if( User.isAuth() ) {
                    this.$router.push({
                        name: "user-contracts-create",
                        query: {
                            period: this.period,
                            summ: this.investments
                        }
                    });
                } else {
                    
                    sessionStorage.setItem("after:auth", JSON.stringify({
                        "type" : "rent",
                        "data" : {
                            period: this.period,
                            summ: this.investments
                        }
                    }));
                    
                    this.$router.push({
                        name: "auth-login",
                    });
                }
                
            },
            
        },
        
        computed: {

            fee() {
                return (this.period_profit * 0.025) * this.market_price;
            },

            fee_btc() {
                return this.period_profit * 0.025;
            },
            
            service() {
                return (this.period_profit * 0.05) * this.market_price;
            },

            service_btc() {
                return this.period_profit * 0.05;
            },
            
            difficult() {
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let growing = temp * this.period;
                let diff = parseInt(this.$user.contracts.values.D) + growing;

                if( diff <= 10000000000000 ) {
                    diff = 10000000000000;
                }
                                
                return parseInt(diff);
            },
            
            market_price() {
                // формула вычета сложно процента 
                if (User.currency === 'USD') {
                    return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
                } else if  (User.currency === 'BTC') {
                    return 1;
                } else {
                    return this.$blockchain.tickers.values[User.currency].last;
                }
            },

            market_price_btc() {
                
                let temp = this.$blockchain.tickers.values['BTC'].last * ( this.market_price_percent / 100 );
                let growing = temp * this.period;
                
                return this.$blockchain.tickers.values['BTC'].last + growing;
                
            },

            period_profit() {
                // через средний рост сложности майнинга
                // return (86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( ((this.difficult + parseInt(this.$user.contracts.values.D)) / 2 ) * Math.pow(2, 32) );

                let profit = 0;
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let diff = parseInt(this.$user.contracts.values.D);

                for (let i = 0; i < this.period; i++) {
                   profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

                   diff += temp;
                }

                return profit;
            },
            
            price_in_btc() {
                return this.current_price / this.period / this.$blockchain.tickers.values.USD.last;                
            },
            
            price() {
                return this.price_in_btc * this.$blockchain.tickers.values.USD.last;
            },
                
            summary() {
                //v2
                return (this.period_profit * this.market_price) - this.energy_payout;

                //v1
                // return (this.period_profit * this.market_price) - this.energy_payout * this.period;
            },

            summary_in_btc() {
                return this.period_profit - this.energy_payout_btc - this.fee_btc - this.service_btc;
            },
            
            investments() {
                return this.total_btc_price * this.$blockchain.tickers.values[User.currency].last; 
            },
            
            percent() {
                return (( (this.summary - this.fee - this.service - this.investments)  / this.investments) * 100).toFixed(2);
            },

            month_energy_payout_usd() {
                // за месяц
                return this.total_ths * ( this.$user.contracts.values.E * 24 * 30 );
            },
            
            // //v1
            // energy_payout() {
            //     if( User.currency === "BTC" ) {
            //         return this.energy_payout_btc; 
            //     }
                
            //     return this.energy_payout_btc * this.$blockchain.tickers.values[User.currency].last;
            // },

            energy_payout() {
                let energy_btc = 0;

                let month_energy_usd = this.month_energy_payout_usd
                let usd_rate = this.$blockchain.tickers.values.USD.last; 

                for (let i = 0; i < this.period; i++) {
                    energy_btc += month_energy_usd / usd_rate;

                    usd_rate = usd_rate * (1 + this.market_price_percent / 100);
                }

                if (User.currency === "BTC") {
                    return energy_btc;
                } else if (User.currency === "USD") {
                    return month_energy_usd * this.period;
                } else {
                    return energy_btc * this.$blockchain.tickers.values[User.currency].last;
                }
            },

            energy_payout_btc() {
                let energy_btc = 0;

                let month_energy_usd = this.month_energy_payout_usd
                let usd_rate = this.$blockchain.tickers.values.USD.last; 

                for (let i = 0; i < this.period; i++) {
                    energy_btc += month_energy_usd / usd_rate;

                    usd_rate = usd_rate * (1 + this.market_price_percent / 100);
                }

                return energy_btc;
            }
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {
        }

    }

</script>